<template>
  <div>
    <gib-air-form
      v-if="isGibAirRateFormActive"
      :is-active.sync="isGibAirRateFormActive"
      :gibAirRate="gibAirRate"
      @form-submitted="formSubmitted"
    />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="openForm"
          >
            <span class="text-nowrap">Add Gib Air Rate</span>
          </b-button>
        </div>
      </div>

      <b-table
        ref="refGibAirRateTable"
        class="position-relative"
        :items="getGibAirRateList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No records found"
      >
        <template #cell(paxFee)="data">
          <p class="font-weight-bold d-block text-nowrap">
            £{{ data.item.paxFee }}
          </p>
        </template>

        <template #cell(paxFeeTaxi)="data">
          <p class="font-weight-bold d-block text-nowrap">
            £{{ data.item.paxFeeTaxi }}
          </p>
        </template>

        <template #cell(paxFeeGtaoffice)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.paxFeeGtaoffice }}
          </p>
        </template>

        <template #cell(baggageFeePerBag)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.baggageFeePerBag }}
          </p>
        </template>

        <template #cell(baggageFeePerBagTaxi)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.baggageFeePerBagTaxi }}
          </p>
        </template>

        <template #cell(baggageFeePerBagGtaoffice)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.baggageFeePerBagGtaoffice }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editRate(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BDropdown, BDropdownItem, BRow, BTable } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import GibAirForm from '@/modules/rate/components/GibAirForm.vue';
import useGibAirList from "@/modules/rate/composables/useGibAirList";
import useGibAirRate from "@/modules/rate/composables/useGibAirRate";
import Swal from 'sweetalert2';

export default {
  setup() {
    const { deleteGibAirRate } = useGibAirRate();
    const { refGibAirRateTable, tableColumns, getGibAirRateList } = useGibAirList();

    const gibAirRateEmpty = {
      baggageFeePerBagGtaoffice: null,
      baggageFeePerBag: null,
      id: null,
      paxFeeGtaoffice: null,
      paxFee: null,
    };

    const isGibAirRateFormActive = ref(false);
    const gibAirRate = ref({ ...gibAirRateEmpty });

    const editRate = (rate) => {
      gibAirRate.value = { ...rate };
      isGibAirRateFormActive.value = true;
    };

    const formSubmitted = () => {
      isGibAirRateFormActive.value = false;
      gibAirRate.value = { ...gibAirRateEmpty };
      refGibAirRateTable.value.refresh();
    };

    const openForm = () => {
      gibAirRate.value = { ...gibAirRateEmpty };
      isGibAirRateFormActive.value = true;
    };

    const removeRate = async (rate) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteGibAirRate(rate);

      refGibAirRateTable.value.refresh();
    };

    return {
      // Reactive
      gibAirRate,
      isGibAirRateFormActive,
      tableColumns,
      refGibAirRateTable,

      // Method
      editRate,
      formSubmitted,
      getGibAirRateList,
      openForm,
      removeRate
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    BTable,
    GibAirForm,
  }
};
</script>