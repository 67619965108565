import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useGibAirRate = () => {
  const DEFAULT_SETTING_ID = 1;
  const toast = useToast();

  const createGibAirRate = async (gibAirRate) => {
    try {
      await store.dispatch('gibAirRate/create', gibAirRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the Gib Air rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const deleteGibAirRate = async (gibAirRate) => {
    try {
      await store.dispatch('gibAirRate/remove', gibAirRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the Gib Air rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getGibAirRates = async () => {
    try {
      return await store.dispatch('gibAirRate/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of Gib Air rates',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getGibAirRate = async () => {
    try {
      return await store.dispatch(
        'gibAirRate/getGibAirRate',
        DEFAULT_SETTING_ID
      );
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of Gib Air rates',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateGibAirRate = async (gibAirRate) => {
    try {
      await store.dispatch('gibAirRate/update', gibAirRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the Gib Air rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createGibAirRate,
    deleteGibAirRate,
    getGibAirRate,
    getGibAirRates,
    updateGibAirRate,
  };
};

export default useGibAirRate;
