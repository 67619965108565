var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-gib-air-rate-sidebar","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.gibAirRateData.id ? 'Update' : 'Add New')+" Gib Air Rate ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newGibAirRateForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-form-group',{attrs:{"label":"Pax Fee","label-for":"pax-fee"}},[_c('validation-provider',{attrs:{"name":"Pax Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pax-fee","state":errors.length > 0 ? false : null,"name":"pax-fee","type":"number","step":".01"},model:{value:(_vm.gibAirRateData.paxFee),callback:function ($$v) {_vm.$set(_vm.gibAirRateData, "paxFee", $$v)},expression:"gibAirRateData.paxFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"GTA Office Pax Fee","label-for":"gta-office-fee"}},[_c('validation-provider',{attrs:{"name":"GTA Office Pax Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"gta-office-fee","state":errors.length > 0 ? false : null,"name":"gta-office-fee","type":"number","step":".01"},model:{value:(_vm.gibAirRateData.paxFeeGtaoffice),callback:function ($$v) {_vm.$set(_vm.gibAirRateData, "paxFeeGtaoffice", $$v)},expression:"gibAirRateData.paxFeeGtaoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Taxi Fee","label-for":"taxi-fee"}},[_c('b-form-input',{attrs:{"id":"taxi-fee","name":"taxi-fee","disabled":""},model:{value:(_vm.paxFeeTaxi),callback:function ($$v) {_vm.paxFeeTaxi=$$v},expression:"paxFeeTaxi"}})],1),_c('b-form-group',{attrs:{"label":"Baggage Fee","label-for":"baggage-fee"}},[_c('validation-provider',{attrs:{"name":"Baggage Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bagagge-fee","state":errors.length > 0 ? false : null,"name":"baggage-fee","type":"number","step":".01"},model:{value:(_vm.gibAirRateData.baggageFeePerBag),callback:function ($$v) {_vm.$set(_vm.gibAirRateData, "baggageFeePerBag", $$v)},expression:"gibAirRateData.baggageFeePerBag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"GTA Office Baggage Fee","label-for":"gta-fee-bag"}},[_c('validation-provider',{attrs:{"name":"GTA Office Baggage Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"gta-fee-bag","state":errors.length > 0 ? false : null,"name":"gta-fee-bag","type":"number","step":".01"},model:{value:(_vm.gibAirRateData.baggageFeePerBagGtaoffice),callback:function ($$v) {_vm.$set(_vm.gibAirRateData, "baggageFeePerBagGtaoffice", $$v)},expression:"gibAirRateData.baggageFeePerBagGtaoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Taxi Baggage Fee","label-for":"taxi-fee-bag"}},[_c('b-form-input',{attrs:{"id":"taxi-fee-bag","name":"taxi-fee-bag","disabled":""},model:{value:(_vm.baggageFeeTaxi),callback:function ($$v) {_vm.baggageFeeTaxi=$$v},expression:"baggageFeeTaxi"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.gibAirRateData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }