import { ref } from '@vue/composition-api';
import useGibAirRate from './useGibAirRate';

const useGibAirList = () => {
  const { getGibAirRates } = useGibAirRate();

  const refGibAirRateTable = ref(null);
  const tableColumns = [
    { key: 'paxFee' },
    { key: 'paxFeeTaxi', label: 'Taxi Pax Fee' },
    { key: 'paxFeeGtaoffice', label: 'GTA Office Pax Fee' },
    { key: 'baggageFeePerBag', label: 'Baggage Fee' },
    { key: 'baggageFeePerBagTaxi', label: 'Taxi Fee Per Bag' },
    {
      key: 'baggageFeePerBagGtaoffice',
      label: 'GTA Office Fee Per Bag',
    },
    { key: 'actions' },
  ];

  const getGibAirRateList = (_, callback) => {
    getGibAirRates().then((rates) => {
      callback(rates);
    });
  };

  return {
    // Reactive
    refGibAirRateTable,
    tableColumns,

    // Method
    getGibAirRateList,
  };
};

export default useGibAirList;
